import axios from 'axios';
import { ActionTree } from 'vuex';
import Cookies from 'js-cookie'; // Importar js-cookie
import { AuthState } from './state';
import { RootState } from '@/store/types';

const API_URL = process.env.VUE_APP_URL_BACKEND;

const actions: ActionTree<AuthState, RootState> = {

    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    async requestOtp({ commit }, document ) {   
        /* eslint-disable no-useless-catch */
        try {            
            // Realizar la solicitud para enviar el OTP
            const token = Cookies.get('authToken'); // Recuperar el token de las cookies
            if (token) {
                axios.defaults.headers.common['Authorization'] = `Token ${token}`;
            }
            
            axios.defaults.withCredentials = true;

            const response = await axios.post(`${API_URL}api/auth/send-otp/`,  {document} , { timeout: 60000 }); // Aumenta el timeout a 10000 ms
            
            const newToken = response.data.token;
            if (newToken) {
                // Generar token y almacenarlo
                Cookies.set('authToken', newToken); // Guardar el token en las cookies
                commit('SET_TOKEN', newToken); // Asegúrate de tener un mutation SET_TOKEN en tu store
                // Guardar el documento en el estado para uso posterior si es necesario
                commit('SET_DOCUMENT', document); // Asegúrate de tener un mutation SET_DOCUMENT en tu store
                // Configurar el token en los encabezados de las solicitudes de Axios
                axios.defaults.headers.common['Authorization'] = `Token ${newToken}`;
                return { success: true, message: 'OTP enviado y token generado correctamente' };
              } else {
                throw new Error('Documento no encontrado en la base de datos');
              }
        } catch (error) {
            // eslint-disable-next-line
            console.error('Error en requestOtp:', error);
            throw error;
        }
        /* eslint-enable no-useless-catch */
    },


    async loginWithOtp({ commit }, { document, otp }) {

        /* eslint-disable no-useless-catch */
        try {
            // Validar el documento y la OTP antes de continuar
            if (!document || !otp) {
                throw new Error('Documento y OTP son obligatorios');
            }
            
            const token = Cookies.get('authToken'); // Recuperar el token de las cookies
            if (token) {
                axios.defaults.headers.common['Authorization'] = `Token ${token}`;
            }

            // Verificar la estructura del JSON
            const requestData = { document, otp: otp.value || otp }; // Asegurarse de que `otp` sea un valor simple
            // eslint-disable-next-line
            // console.log("JSON enviado:", JSON.stringify(requestData));

            // Configurar Axios para incluir cookies en todas las solicitudes
            axios.defaults.withCredentials = true;

            // Realizar la solicitud al servicio de autenticación
            const response = await axios.post(`${API_URL}api/auth/login-with-otp/`, requestData);
            
            const newToken = response.data.token;
        
            // Actualizar el estado de autenticación en Vuex
            commit('SET_AUTHENTICATED', true);

            // Actualizar el token si se recibe uno nuevo
            if (newToken) {
                Cookies.set('authToken', newToken); // Actualizar el token en las cookies
                commit('SET_TOKEN', newToken); // Mutación para establecer el nuevo token en el estado de Vuex
                axios.defaults.headers.common['Authorization'] = `Token ${newToken}`;
            }

            // Guardar el documento en el estado de Vuex para uso posterior
            commit('SET_DOCUMENT', document);

            // Guardar el documento en las cookies
            Cookies.set('document', document);

            return { success: true, message: 'Inicio de sesión exitoso' };
        } catch (error) {
            // Manejar errores y lanzar nuevamente para que el componente pueda manejarlos
            throw error;
        }
        /* eslint-enable no-useless-catch */    
    },
    

    

    async logout({ commit }) {
        commit('LOGOUT');
        localStorage.removeItem('authToken');
        Cookies.remove('authToken');
        Cookies.remove('document');
        delete axios.defaults.headers.common['Authorization'];
    },
};




export default actions;