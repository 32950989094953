import { GetterTree } from 'vuex';
import state, { DocumentUploadState } from './state';
import { RootState } from '../types';

const getters: GetterTree<DocumentUploadState, RootState> = {
    documentUrl: (state: { documentUrl: any; }) => state.documentUrl,
    document2Url: (state: { document2Url: any; }) => state.document2Url,
    nominaUrl: (state: { nominaUrl: any; }) => state.nominaUrl,
};

export default getters;