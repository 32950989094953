import { ActionTree } from 'vuex';
import { RootState } from '@/store/types';
import { SimulateCreditState } from './state';
import Cookies from 'js-cookie'; // Importar js-cookie
import axios, { AxiosResponse } from 'axios';
import { CreditRequestResponse, FormDataPayload } from '../types';

const API_URL = process.env.VUE_APP_URL_BACKEND;

const actions: ActionTree<SimulateCreditState, RootState> = {
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    async simulateCredit({ commit }, payload) {
        try {
            const { amount, installments, days } = payload; // Obtén los datos del estado o parámetros necesarios
            
            // Obtener el token de las cookies
            const token = Cookies.get('authToken'); // Recuperar el token de las cookies
            if (!token) {
                throw new Error('No se encontró el token de autenticación');   
            }

            // Configurar Axios con el token de autenticación
            axios.defaults.headers.common['Authorization'] = `Token ${token}`;
            axios.defaults.withCredentials = true;

            // Construir el JSON de solicitud
            const requestData = { amount, installments, days };

            // Realizar la solicitud a simulate-credit endpoint
            // eslint-disable-next-line
            const response: AxiosResponse<any> = await axios.post(`${API_URL}api/auth/simulate-credit/`, requestData);
           

            const newToken = response.headers.authorization;

            // Si se recibe un nuevo token en la respuesta, actualizarlo
            if (newToken) {
                Cookies.set('authToken', newToken); // Actualizar el token en las cookies
                commit('auth/SET_TOKEN', newToken, { root: true }); // Actualizar el token en el estado de autenticación
                axios.defaults.headers.common['Authorization'] = `Token ${newToken}`;
            }

            // Actualizar el resultado de la simulación en el estado
            commit('SET_SIMULATION_RESULT', response.data);

            return response.data; // Devolver los datos de respuesta si es necesario
            
        } catch (error) {
            if (axios.isAxiosError(error)) {
                // eslint-disable-next-line
                console.error('Error durante la simulación de crédito:', error.response?.data || error.message);
            } else {
                // eslint-disable-next-line
                console.error('Error durante la simulación de crédito:', error);
            }
            throw error;
        }
    },
};

export default actions;