import { createRouter, createWebHistory, RouteRecordRaw } from 'vue-router';
import { useStore } from '@/store';

// Importación dinámica (lazy loading) de componentes
const HomeView = () => import('@/views/HomeView.vue')
const SimulateCreditView = () => import('../components/simulatecreditview/SimulateCreditView.vue')
const LoginWithOtpView = () => import('../components/loginwithotpview/LoginWithOtpView.vue')
const RequestOtpView = () => import('../components/requestotpview/RequestOtpView.vue')
const Layout = () => import('@/components/Layout.vue')
const RequestCreditView = () => import('@/components/requestcreditview/RequestCreditView.vue')
const DocumentViewerView = () => import('@/components/documentviewerview/DocumentViewerView.vue')

const routes: Array<RouteRecordRaw> = [
  {
    path: '/',
    component: Layout, // Usar Layout como componente principal
    children:[
      {
          path: '',
          name: 'Home', // Tu vista principal (HomeView)
          component: HomeView
      },
      {
        path: '/home',
        name: 'HomeAlias', // Tu vista principal (HomeView)
        component: HomeView
      },
      {
          path: '/auth/send-otp',
          name: 'SendOtp',  // Cambiado a 'SendOtp' para evitar confusión con 'RequestOtp'
          component: RequestOtpView
      },
      {
          // path: '/auth/login-with-otp/:document',
          path: '/login',
          name: 'LoginWithOtp',
          component: LoginWithOtpView,
          props: true
      },
      {
          path: '/auth/credit-simulator',
          name: 'simulateCredit',
          component: SimulateCreditView,
          meta: { requiresAuth: true} 
      },
      {
          path: '/auth/request-credit', 
          name: 'RequestCredit', 
          component: RequestCreditView,
      },
      {
          path: '/auth/view-documents',
          name: 'DocumentViewer',
          component: DocumentViewerView,
      },
      {
        path: 'auth/validate-otp/',
        name: 'verifyOtp',
        component: DocumentViewerView,
      }
    ],
  }, 
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
});

router.beforeEach((to, from, next) => {
  const store = useStore(); 
  
  if (to.matched.some(record => record.meta.requiresAuth)) {
    if (!store.state.auth.isAuthenticated) {
      next('/');
    } else {
      next();
    }
  } else {
    next();
  }
});

export default router;
