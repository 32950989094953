import { MutationTree } from 'vuex';
import { DocumentUploadState } from './state';

const mutations: MutationTree<DocumentUploadState> = {
    setDocumentUrls(state, payload: { documentUrl: string; document2Url: string; nominaUrl: string}) {
        state.documentUrl = payload.documentUrl;
        state.document2Url = payload.document2Url;
        state.nominaUrl = payload.nominaUrl;
    },
};

export default mutations;