export interface DocumentUploadState {
    documentUrl: string | null;
    document2Url: string | null;
    nominaUrl: string | null;
}

const state: DocumentUploadState = {
    documentUrl: null,
    document2Url: null,
    nominaUrl: null,
};

export default state;