import { ActionTree, ActionContext } from 'vuex';
import { RootState } from '../types';
import { DocumentSignState } from './state';
import axios, { AxiosResponse }  from 'axios';
import Cookies from 'js-cookie';

const API_URL = process.env.VUE_APP_URL_BACKEND;

const actions: ActionTree<DocumentSignState, RootState> = {
    async verifyOtp({ commit }, { document, otp }) {
      /* eslint-disable no-useless-catch */  
      try {
          if (!document || !otp) {
            throw new Error('Documento O OTP No se estan recibiendo');
          }

          const token = Cookies.get('authToken'); // Recuperar el token de las cookies
            if (token) {
                axios.defaults.headers.common['Authorization'] = `Token ${token}`;
          }
          
          const requestData = { document, otp: otp.value || otp };

          axios.defaults.withCredentials = true;
         
          const response = await axios.post(`${API_URL}api/auth/validate-otp/`, requestData);
          
          // Actualizar el token en las cookies si es necesario
          const newToken = response.data.token;

          // Actualizar el estado de autenticación en Vuex
          commit('SET_AUTHENTICATED', true);

          // console.log('verifyOtp response:', response);
          // Actualizar el token si se recibe uno nuevo
          if (newToken) {
            Cookies.set('authToken', newToken); // Actualizar el token en las cookies
            commit('SET_TOKEN', newToken); // Mutación para establecer el nuevo token en el estado de Vuex
            axios.defaults.headers.common['Authorization'] = `Token ${newToken}`;
          }

          commit('SET_VERIFICATION_STATUS', response.data.message || 'Success');

          return { success: response.data.success, credit_number: response.data.credit_number };
          // return response;

        } catch (error) {
            // Manejar errores y lanzar nuevamente para que el componente pueda manejarlos
            throw error;
        }
        /* eslint-enable no-useless-catch */
    },
};

export default actions;